import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { makeStyles } from '@material-ui/core'



const Chip = props => {

    const data = useStaticQuery(graphql`
        query {
            AUX_COLOR: setting(environmentName: {eq: "AUX_COLOR"}) {
                value
            }
            AUX_COLOR_COMPLEMENT: setting(environmentName: {eq: "AUX_COLOR_COMPLEMENT"}) {
                value
            }
            MAIN_COLOR: setting(environmentName: {eq: "MAIN_COLOR"}) {
                value
            }
        }
    `)
    const AUX_COLOR = data.AUX_COLOR.value
    const AUX_COLOR_COMPLEMENT = data.AUX_COLOR_COMPLEMENT.value
    const MAIN_COLOR = data.MAIN_COLOR.value

    const useStyles = makeStyles({
        chip: {
            backgroundColor: `${AUX_COLOR_COMPLEMENT}`,
            border: `solid 1px ${AUX_COLOR}`,
            marginRight: '5px',
            marginBottom: '5px',
            padding: '8px',
            borderRadius: '20px',
            fontSize: '12px',
            textTransform: 'lowercase',
            '& a': {
                textDecoration: 'none',
                color: `${MAIN_COLOR}`
            }
        }
    });

    const classes = useStyles()
    return (
        <span className={classes.chip}>
            <Link to={props.link}>{props.children}</Link>
        </span>
    )
}

export default Chip